<template>
    <div class="algolia-product-search">
        <ResidentHeader
            page-title="Catalogue"
            class="mb-4"
            :displayResidentMenu="!isMobile"
        />
        <CatalogItemHeader
            :breadcrumbItems="breadcrumbItems"
            :residentId="resident_id"
            :disableBasketButton="!isEditionAllowed"
            class="mb-4"
        />
        <AlgoliaInstantSearch
            v-if="loaded"
            class="global"
        >
            <AlgoliaRefinementListMask
                attribute="institutions"
            />

            <div class="search-header">
                <AlgoliaAutocomplete
                    class="search-box"
                    placeholder="Rechercher un produit ou service"
                    :searchOnType="true"
                    :externalSearch="false"
                    @change="onSearchChange"
                />
                <AlgoliaSortBy
                    class="sort-by"
                    label="Trier par"
                    :sortItems="sortItems"
                />
            </div>

            <div
                v-if="searchTerms"
                class="terms"
            >« {{ searchTerms }} »</div>

            <ais-hits
                class="content"
                :transform-items="transformItems"
            >
                <template v-slot="{ items }">
                    <div
                        v-if="items.length < 1"
                        class="no-results"
                    >
                        Nous sommes désolés, il n’y a pas de résultat pour votre recherche.
                    </div>
                    <div class="results">
                        <div
                            v-show="(isMobile && filterOpened) || !isMobile"
                            class="filter-modal"
                            :class="{ 'filter-modal-open': filterOpened }"
                        >
                            <div class="modal-header">
                                <HBtn
                                    class="back-btn"
                                    icon="ArrowLeftIcon"
                                    icon-only
                                    style-type="tertiary"
                                    @click="onFilterCloseBtnClick"
                                ></HBtn>
                                <div class="title">Filtres</div>
                            </div>
                            <div class="modal-content">
                                <div class="filters">
                                    <div class="filter-header">Filtres</div>
                                    <AlgoliaCheckboxList
                                        class="filter"
                                        attribute="brandLabel"
                                        placeholder="Rechercher une marque"
                                        label="Marques"
                                    />
                                    <AlgoliaToggleList
                                        class="filter"
                                        attribute="size"
                                        label="Tailles"
                                    />
                                    <AlgoliaColorList
                                        class="filter"
                                        attribute="color"
                                        label="Couleurs"
                                    />
                                    <AlgoliaRange
                                        class="filter prices"
                                        attribute="price"
                                        label="Prix"
                                    />
                                </div>
                            </div>
                            <div
                                v-show="filterOpened"
                                class="modal-footer"
                            >
                                <div class="action-bar">
                                    <HBtn
                                        class="apply-btn"
                                        style-type="secondary"
                                        @click="onApplyBtnClick"
                                    >Appliquer</HBtn>
                                </div>
                            </div>
                        </div>
                        <div class="products">
                            <AlgoliaStats
                                v-if="items.length > 0"
                            />
                            <HRow :gap="3">
                                <HCol
                                    v-for="product in items"
                                    :key="product.objectID"
                                    :cols="12"
                                    :colsSm="6"
                                    :colsMd="4"
                                    :colsLg="3"
                                >
                                    <ProductCard
                                        :product="product"
                                        :displayPcp="isProductIsInPcp(product)"
                                    />
                                </HCol>
                            </HRow>
                            <AlgoliaPagination
                                @change="onPageChange"
                            />
                            <div
                                v-show="isMobile && !filterOpened"
                                class="filter-bar"
                            >
                                <HBtn
                                    class="filter-btn"
                                    icon="ChevronUpIcon"
                                    rounded
                                    @click="onFilterOpenBtnClick"
                                >Filtres</HBtn>
                            </div>
                        </div>
                    </div>
                </template>
            </ais-hits>
        </AlgoliaInstantSearch>

        <BtnScrollToTop />
    </div>
</template>

<script>
import _ from 'lodash'
import async from 'async'
import {
    AisHits
} from 'vue-instantsearch'
import Oauth from "@/oauth"
import appsettings from '@/appsettings'

import Routes from '@/constants/routes'

import catalogMixins from './catalog.mixins'

import ResidentHeader from '@/views/residents/components/ResidentHeader'

import { HCard, HBtn, HDialog, HRow, HCol } from '@happytal/bo-ui-library'
import BtnScrollToTop from '@/components/BtnScrollToTop'

import CatalogItemHeader from './components/CatalogItemHeader'
import ProductCard from './components/ProductCard'
import AlgoliaInstantSearch from './components/AlgoliaInstantSearch'
import AlgoliaRefinementListMask from './components/AlgoliaRefinementListMask'
import AlgoliaCheckboxList from './components/AlgoliaCheckboxList'
import AlgoliaToggleList from './components/AlgoliaToggleList'
import AlgoliaColorList from './components/AlgoliaColorList'
import AlgoliaRange from './components/AlgoliaRange'
import AlgoliaPagination from './components/AlgoliaPagination'
import AlgoliaAutocomplete from './components/AlgoliaAutocomplete'
import AlgoliaSortBy from './components/AlgoliaSortBy'
import AlgoliaStats from './components/AlgoliaStats'

const vueAppAlgoliaSortItems = appsettings.VUE_APP_ALGOLIA_SORT_ITEMS

export default {
    mixins: [
        catalogMixins
    ],
    props: [
        'resident_id',
        'category_id'
    ],
    components: {
        ResidentHeader,
        CatalogItemHeader,
        BtnScrollToTop,

        HRow,
        HCol,
        HCard,
        HBtn,
        HDialog,

        AisHits,

        AlgoliaInstantSearch,
        AlgoliaRefinementListMask,
        AlgoliaCheckboxList,
        AlgoliaToggleList,
        AlgoliaColorList,
        AlgoliaRange,
        AlgoliaPagination,
        AlgoliaAutocomplete,
        AlgoliaSortBy,
        AlgoliaStats,

        ProductCard
    },
    data () {
        return {
            loaded: false,
            filterOpened: false,
            searchTerms: '',
            sortItems: JSON.parse(vueAppAlgoliaSortItems)
        }
    },
    computed: {
        isEditionAllowed () {
            return this.$aclChecker.some(this.$acl, [
                'isTutor',
                'isFamily',
                'isHappytalSupport',
                'isHappytalEmployee'
            ])
        },
        isMobile () {
            return this.$breakpoint.smAndDown
        },
        breadcrumbItems () {
            return [
                {
                    type: 'catalog',
                    label: 'Catalogue',
                    route: {
                        name: Routes.ResidentCatalog
                    },
                    iconForMobile: 'BuildingStoreIcon'
                },
                {
                    type: 'search',
                    label: 'Résultats de recherche'
                }
            ]
        }
    },
    methods: {
        getUserId () {
            return this.$store.getters['dataUser/getUserId']()
        },
        getInstitutionId () {
            return this.$store.getters['dataUser/getInstitutionId']()
        },
        getResidentPcp (residentId) {
            return this.$store.getters['dataPcps/getResidentPcp'](residentId)
        },
        getCurrentPcp () {
            return this.getResidentPcp(this.resident_id)
        },
        isProductIsInPcp (product) {
            const pcp = this.getCurrentPcp()
            if (!pcp) {
                return false
            }
            let result = false
            switch (product.type) {
                case 'Product':
                    result = _.find(pcp.shoppingListProducts, (slp) => {
                        const variantCode = _.get(slp, 'meta.product.data.variantCode', null)
                        if (variantCode) {
                            return variantCode == _.get(product, 'variantCode', null)
                        }
                        else {
                            return _.get(slp, 'meta.product.productSku') == product.code
                        }
                    })
                    break
                case 'Service':
                    result = _.find(pcp.shoppingListServices, (slp) => {
                        return _.get(slp, 'meta.serviceModel.code') == product.code
                    })
                    break
            }
            return Boolean(result)
        },
        transformItems (items) {
            return items.map((item) => {
                let route = {
                    params: {
                        resident_id: this.resident_id
                    }
                }
                let code = null
                switch (item.type) {
                    case 'Product':
                        code = item.productSku
                        route.params.product_id = item.productSku
                        if (item.variantCode) {
                            route.name = Routes.CatalogVariant
                            route.params.variant_id = item.variantCode
                        }
                        else {
                            route.name = Routes.CatalogProduct
                        }
                        break
                    case 'Service':
                        code = item.serviceModelCode
                        route.name = Routes.CatalogService
                        route.params.service_model_id = item.serviceModelCode
                        break
                }
                return {
                    id: item.objectID,
                    code,
                    variantCode: item.variantCode,
                    title: item.title,
                    imageUrl: item.image,
                    brand: item.brandLabel,
                    price: item.price,
                    quantity: item.formFactor,
                    route,
                    type: item.type
                }
            })
        },
        onFilterOpenBtnClick (e) {
            this.filterOpened = true
        },
        onFilterCloseBtnClick (e) {
            this.filterOpened = false
        },
        onApplyBtnClick (e) {
            this.filterOpened = false
        },
        onSearchChange (search) {
            this.searchTerms = search
        },
        onPageChange () {
            this.scrollToTop()
        },
        updateDraftPopup () {
            setTimeout(() => {
                    this.$store.commit('dataDrafts/SET_POPUP_VISIBLE', {
                        visible: false
                    })
                },
                4000
            )
        },
        showInfoToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration: 5000,
                action: [
                    {
                        text: 'Fermer',
                        onClick: (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        },
        scrollToTop () {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }
    },
    async mounted () {
        try {
            this.scrollToTop()

            if (!this.$store.state.dataResidents.isRegistered) {
                await this.$store.dispatch('dataResidents/getResidents', {
                    userId: this.getUserId(),
                    institutionId: this.getInstitutionId()
                })
            }

            await this.$store.dispatch('dataCatalogCategories/getCategories', {
                institutionId: this.getInstitutionId()
            })

            await this.$store.dispatch('dataPcps/getResidentPcp', {
                residentId: this.resident_id
            })

            const resident = this.getResidentById(this.resident_id)
            Oauth.checkUserRight(resident, this.$router, this.$vs.loading)
            this.updateDraftPopup()

            this.searchTerms = _.get(this.$router, 'currentRoute.query.query')

            this.loaded = true
        }
        catch (error) {
            console.error(error)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';
.algolia-product-search {

    .global {

        .reset-btn {

        }
        .search-header {
            display: flex;
            align-items: center;
            margin: 5px 0px 0px 0px;

            @media (max-width: map-get($breakpoints, sm)) {
                flex-wrap: wrap;
            }

            .search-box {
                flex-grow: 1;

                ::v-deep .aa-Autocomplete {
                    width: 100%;
                }
            }
            .sort-by {
                margin: 0px 0px 0px 30px;
                z-index: 2;
            }
        }
        .terms {
            padding: 20px 0px 20px 0px;
            text-align: center;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            color: #2C2C2C;
        }
        .content {

            .no-results {
                margin: 30px 0px 30px 0px;
                font-weight: 500;
                font-size: 17px;
                line-height: 20px;
                text-align: center;
                color: black;
            }
            .results {
                display: flex;

                .filter-modal {

                    .reset-filters-btn {
                        border-top: 1px solid #EEEEEE;
                    }
                    .modal-header {
                        display: none;

                        .title {

                        }
                    }
                    .modal-content {

                        .filters {
                            width: 260px;
                            flex-shrink: 0;

                            .filter-header {
                                padding: 20px 0px 20px 0px;
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 20px;
                                color: #000000;
                            }
                            .filter {

                            }
                        }
                    }
                    .modal-footer {

                        .action-bar {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 54px;
                            background-color: var(--v-primary-base);

                            .reset-btn {
                                border: 2px solid white;
                            }
                            .apply-btn {
                                margin: 0px 0px 0px 20px;
                                border: 2px solid white;
                                background: white;
                            }
                        }
                    }
                }
                .filter-modal-open {
                    display: flex;
                    flex-direction: column;
                    position: fixed;
                    left: 0px;
                    top: 0px;
                    right: 0px;
                    bottom: 0px;
                    z-index: 15;

                    .modal-header {
                        display: flex;
                        align-items: center;
                        flex-shrink: 0;
                        height: 54px;
                        background: white;
                        padding: 0px 20px 0px 20px;
                        border-bottom: 1px solid #EEEEEE;
                        box-shadow: 0 0 8px 0 rgba(0,0,0,.1);

                        .title {
                            margin: 0px 0px 0px 15px;
                            font-weight: 600;
                            font-size: 17px;
                            line-height: 20px;
                            text-transform: uppercase;
                            color: black;
                        }
                    }
                    .modal-content {
                        height: calc(100% - 108px);
                        overflow-y: auto;
                        background: white;

                        .filters {
                            flex-grow: 1;
                            width: auto;

                            @media (max-width: map-get($breakpoints, sm)) {
                                padding: 0px 0px 20px 0px;
                            }

                            .filter {
                                padding: 0px 20px 0px 20px;
                            }
                        }
                    }
                    .modal-footer {
                        flex-shrink: 0;
                        height: 54px;
                    }
                }
                .products {
                    flex-grow: 1;
                    margin: 20px 0px 20px 40px;

                    @media (max-width: map-get($breakpoints, sm)) {
                        margin: 20px 0px 20px 0px;
                    }

                    .filter-bar {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 54px;
                        position: fixed;
                        left: 0px;
                        right: 0px;
                        bottom: 0px;
                        z-index: 2;

                        .filter-btn {

                        }
                    }
                    .pagination {

                    }
                }
            }
        }
    }
}
</style>
